import { ColumnOrder } from "../../../components/core/Datagrid";
import AppConstant from "../../../utils/appConstant";
import { Garage } from "../../garages/types/GarageTypes";
import { Contact } from "../../user/types/userTypes";

export enum WarrantyValue {
  Yes = AppConstant.warrantyYes,
  No = AppConstant.warrantyNo,
}

export interface PartWarrantyPrevInter {
  id: string;
  label: string;
}

export interface PartManufacturer {
  id: string;
  label: string;
}

export interface PartType {
  id: string;
  label: string;
}

export interface CarBrand {
  id: string;
  label: string;
}

export interface CarModel {
  id: string;
  label: string;
}

export enum InterventionSortFields {
  "rowid" = "rowid",
  "ref" = "ref",
  "ref_customer" = "ref_customer",
  "datec" = "datec",
  "fk_statut" = "fk_statut",
}

export type InterventionSort = Partial<
  Record<InterventionSortFields, ColumnOrder>
>;

export enum InterventionFilterFields {
  "ref" = "ref",
  "fk_user_author" = "fk_user_author",
  "fk_statut" = "fk_statut",
  "nom" = "nom",
  "customerCode" = "customerCode",
  "shopCode" = "shopCode",
  "ref_customer" = "ref_customer",
  "year" = "year",
  "month" = "month",
  "partType" = "partType",
  "brand" = "brand",
  "model" = "model",
}

export type InterventionFilter = Partial<
  Record<InterventionFilterFields, string | number>
>;

export interface InterventionProposal {
  sendPropal?: number;
  signedPropal?: number;
}

export interface Intervention {
  id: string;
  ref: string;
  customerRef: string;
  status: InterventionStatus;
  userCreat: string;
  dateCreat: number;
  dateUpdate: number;
  takeCharge?: number;
  propal?: InterventionProposal;
  technicalContact?: Contact;
  waranty: WarrantyValue;
  warrantyPrevInter:
    | PartWarrantyPrevInter
    | {
        id: null;
        label: "";
      }
    | null;
  frequency?: string | null;
  manufacturer?: PartManufacturer;
  partType: PartType;
  technicalInfo: string;
  partRef: string;
  comment: string;
  documents: [
    {
      name: string;
      content: string;
      extension: string;
    },
  ];
  notifications: [];
  shipping: {
    number: string;
    date: string;
    carrier: string;
    track: {
      number: string;
      lastEvent: {
        label: string;
        date: boolean;
        office: string;
      };
      events: [
        {
          label: string;
          date: boolean;
          office: string;
        },
      ];
    };
    method: string;
  };
  export: {
    number: null;
    date: "";
    carrier: null;
    track: null;
    method: string;
  };
  paymentMethod: null;
  proposalChoice: null;
  partNbr: string;
  customer_effect: string;
  diagnostic: string;
  parts_replaced: string;
  frame: string;
  distance: string;
  registration: string;
  carBrand: CarBrand;
  carType: CarModel;
  security_code: string;
  thirdParty?: Garage;
}

export enum InterventionStatus {
  Registered = AppConstant.interStatusRegistered,
  Transmited = AppConstant.interStatusTransmited,
  ToSend = AppConstant.interStatusToSend,
  Sended = AppConstant.interStatusSended,
  Declined = AppConstant.interStatusDeclined,
  Receive = AppConstant.interStatusReceive,
  QuoteReceive = AppConstant.interStatusQuoteReceive,
  QuoteAccepted = AppConstant.interStatusQuoteAccepted,
  PaymentPending = AppConstant.interStatusPaymentPending,
  Repair = AppConstant.interStatusRepair,
  OnTheWay = AppConstant.interStatusOnTheWay,
  Finalized = AppConstant.interStatusFinalized,
  Alert = AppConstant.interStatusAlert,
}

export interface InterventionProposal {
  // Todo : Complete type when components who use it will be migrated to tsx
}

export interface InterventionInvoice {
  // Todo : Complete type when components who use it will be migrated to tsx
}
