import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  InputAdornment,
  TextFieldProps,
} from "@material-ui/core";
import CotroliaTextField from "./CotroliaTextField";
import useDebouncedEffect from "use-debounced-effect";
import { useApi } from "../../../hooks/useApi";

type InputSiretProps = TextFieldProps & {
  onValidate: (isSiretValid: boolean) => void;
};

const CotroliaSiretInput: React.FC<InputSiretProps> = (props) => {
  const { t } = useTranslation();
  const { call } = useApi();
  const [eventCatched, setEventCatched] = useState<
    React.ChangeEvent<HTMLInputElement> | undefined
  >();
  const [siretError, setSiretError] = useState<string>();
  const [isLoading, setLoading] = useState(false);

  useDebouncedEffect(
    () => {
      setSiretError(undefined);
      if (eventCatched?.target.value) {
        setLoading(true);
        call(
          "/supercotrolia/validate/siretNumber?siretNumber=" +
            eventCatched.target.value,
        )
          .then(() => {
            props.onValidate && props.onValidate(true);
          })
          .catch(() => {
            setSiretError(t("SiretNotValid"));
            props.onValidate && props.onValidate(false);
          })
          .finally(() => setLoading(false));
      } else if (props.required) {
        setSiretError(t("SiretRequired"));
        props.onValidate && props.onValidate(false);
      }
    },
    300,
    [eventCatched?.target.value],
  );

  return (
    <CotroliaTextField
      {...props}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setEventCatched(e);
        props.onChange && props.onChange(e);
      }}
      error={!!siretError || props.error}
      helperText={siretError ?? props.helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress color="primary" size={24} />}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default CotroliaSiretInput;
